import { observer } from "mobx-react-lite";
import { useLocalization } from "@progress/kendo-react-intl";
import { useEffect, useRef, useState } from "react";
import { useStores } from "src/stores";
import { useServices } from "src/services";
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridDataStateChangeEvent,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { Button, ToolbarSpacer } from "@progress/kendo-react-buttons";
import { useHistory } from "react-router";
import { Dealer } from "src/utils/classes";
import { DataResult, process, State } from "@progress/kendo-data-query";
import {
  ColumnMenu,
  ColumnMenuCheckboxFilter,
} from "src/components/GridColumnMenus";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import moment from "moment";

const DealersScreen = () => {
  const localizationService = useLocalization();

  const { ui } = useStores();
  const { d4ur } = useServices();
  let history = useHistory();

  const [dataState, setDataState] = useState<State>({
    filter: {
      logic: "and",
      filters: [
        {
          logic: "or",
          filters: [
            { field: "activeGridCell", operator: "eq", value: "Active" },
          ],
        },
      ],
    },
    sort: [
      {
        field: "created_timestamp",
        dir: "desc",
      },
    ],
  });

  const [dealers, setDealers] = useState<Dealer[]>([]);
  const [dataResult, setDataResult] = useState<DataResult>(
    process(dealers, dataState)
  );

  const processDealers = (dealers: any[]): Dealer[] => {
    if (!Array.isArray(dealers)) {
      return [];
    }
    // remove dupes since we are getting event data in this query and there can be events from mulitple seasons
    return Array.from(new Set(dealers.map((d) => d.dealer_id)))
      .map((dealerId) => dealers.find((d) => d.dealer_id === dealerId))
      .map((d) => new Dealer(d));
  };

  useEffect(() => {
    // ui.setIsGettingDealers(true);
    d4ur.getDealers().then((ret) => {
      const processedDealers = processDealers(
        (ret as D4URxFetchResult).result || []
      );
      // ui.setIsGettingDealers(false);

      console.log('processedDealers :', processedDealers);
      setDealers(processedDealers);
      setDataResult(process(processedDealers, dataState));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const DealerActionsCell = (props: GridCellProps) => {
    const { dealer_id } = props.dataItem as Dealer;

    return (
      <td>
        <Button
          className="k-button k-primary"
          onClick={() => {
            history.push(`/retailer/${dealer_id}`);
          }}
        >
          Edit
        </Button>
      </td>
    );
  };

  const DealerNotesCell = (props: GridCellProps) => {
    const { dealer_notes } = props.dataItem as Dealer;

    if (!dealer_notes || !dealer_notes.length || !JSON.parse(dealer_notes.toString()).length) {
      return <td></td>;
    }

    const formattedNotes = JSON.parse(dealer_notes.toString())
      .sort((a: any, b: any) => moment(a.created_at).diff(moment(b.created_at)))
      .map((note: any) => {
        const formattedDateTime = moment(note.created_at).format("MMM DD, YYYY hh:mm A");
        return `${formattedDateTime} - ${note.created_by}: ${note.note}`;
      });

    return (
      <td>
        {formattedNotes.map((note: string, index: number) => (
          <div key={index} style={{ borderBottom: "1px solid lightgrey" }}>{note}</div>
        ))}
      </td>
    );
  };

  const _export = useRef<ExcelExport | null>(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  return (
    <>
      <div id="Dashboard" className="dashboard-page main-content">
        <div className="card-container grid">
          <h3 className="card-title">
            {localizationService.toLanguageString("custom.dealers", "dealers")}
          </h3>
          {/* <Button primary={!ui.events.length} onClick={handleEventRequestButton}>
                        { ui.events.length ?
                            localizationService.toLanguageString('custom.requestNewEvent','requestNewEvent') :
                            localizationService.toLanguageString('custom.beginHere','beginHere')
                        }
                    </Button> */}
          <div className="card-component">
            <ExcelExport data={dataResult.data} ref={_export} fileName={`dealers_export_${moment().format('YYYYMMDD-hhmma')}.xlsx`}>
              <ExcelExportColumn field="dealer_id" title="Dealer ID" />
              <ExcelExportColumn field="dealer_name" title="Dealer Name" />
              <ExcelExportColumn field="parts_code" title="Parts Code" />
              <ExcelExportColumn field="region" title="Region" />
              <ExcelExportColumn field="address" title="Address" />
              <ExcelExportColumn field="address2?" title="Address 2" />
              <ExcelExportColumn field="city" title="City" />
              <ExcelExportColumn field="state" title="State" />
              <ExcelExportColumn field="zip" title="Zip" />
              <ExcelExportColumn field="phone" title="Phone" />
              <ExcelExportColumn field="primary_contact" title="Contact" />
              <ExcelExportColumn field="contact_email" title="Contact Email" />
              <ExcelExportColumn field="contact_phone" title="Contact Phone" />
              <ExcelExportColumn field="secondary_contact" title="Secondary Contact" />
              <ExcelExportColumn field="secondary_contact_email" title="Secondary Contact Email" />
              <ExcelExportColumn field="secondary_contact_phone" title="Secondary Contact Phone" />
              <ExcelExportColumn field="tertiary_contact" title="Tertiary Contact" />
              <ExcelExportColumn field="tertiary_contact_email" title="Tertiary Contact Email" />
              <ExcelExportColumn field="tertiary_contact_phone" title="Tertiary Contact Phone" />
              <ExcelExportColumn field="created_timestamp" title="Created" />
              <ExcelExportColumn field="ffs_event_id" title="FFS Event ID" />
              <ExcelExportColumn field="start_date" title="Event Date" />
              <ExcelExportColumn field="training_completed" title="Training Completed" />
            </ExcelExport>
            <Grid
              data={dataResult}
              sortable={{ mode: "multiple" }}
              {...dataState}
              onDataStateChange={(event: GridDataStateChangeEvent) => {
                console.log("event.dataState", event.dataState);
                setDataResult(process(dealers, event.dataState));
                setDataState(event.dataState);
              }}
              resizable={true}
            >
              <GridToolbar>
                <ToolbarSpacer />
                <button className="k-button k-button-md k-rounded-md k-button-solid" onClick={excelExport}>Export</button>
              </GridToolbar>
              <GridColumn
                field="dealer_name"
                title={localizationService.toLanguageString(
                  "custom.dealer",
                  "dealer"
                )}
                columnMenu={ColumnMenu}
                width={200}
              />
              <GridColumn
                field="created_timestamp"
                title="Signup Date"
                format="{0:E, MMM dd yyyy}"
                filter="date"
                columnMenu={ColumnMenu}
                width={200}
              />
              <GridColumn
                field="approved_dealers"
                title="Allowed"
                columnMenu={ColumnMenuCheckboxFilter(dealers, {
                  searchBox: () => null,
                })}
                width={150}
              />
              <GridColumn
                field="firstTimeDealerGridCell"
                title="First Time Dealer"
                columnMenu={ColumnMenuCheckboxFilter(dealers, {
                  searchBox: () => null,
                })}
                width={150}
              />
              <GridColumn
                field="activeGridCell"
                title="Status"
                columnMenu={ColumnMenuCheckboxFilter(dealers, {
                  searchBox: () => null,
                })}
                width={100}
              />
              <GridColumn title="Notes" cell={DealerNotesCell} width={350} />
              <GridColumn cell={DealerActionsCell} width={300} />
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(DealersScreen);
